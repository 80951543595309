import * as NO from "../no"
import * as EN from "../en"
import { containerOptionID_EN } from "../containerOptionID_EN"
import { containerOptionID_NO } from "../containerOptionID_NO"

export const default_translations = {
	en: {
		containerOptionID: containerOptionID_EN,
		unknown: "Unknown",
		undefined: "Undefined",
		disabled: "Disabled",
		disable: "Disable",
		enabled: "Enabled",
		enable: "Enable",
		delete: "Delete",
		remove: "Remove",
		accepted: "Accepted",
		more: "More",
		type: {
			USE: "Throw",
			EMPTY: "Emptied",
		},
		isStagingMessage: "You are currently operating in the test environment",
		selectDateTime: "Time interval",
		from: "From",
		to: "To",
		property: "Property",
		externalKey: "External key",
		priority: "Priority",
		allocation: "Allocation",
		allocations: "Allocations",
		customerName: "Customer name",
		customerGeoLocationName: "Customer address",
		loading: "Loading",
		error: "Error",
		errors: {
			failedFetch: "Failed to fetch data from the server.",
			failedSave: "Saving failed.",
			failed: "Failed",
		},
		success: {
			saved: "Saved successfully",
		},
		selectPoint: "Select point",
		selectStation: "Select station",
		time: "Time",
		interval: {
			custom: "Custom range",
			"1day": "Today",
			"2days": "Last 2 days",
			"3days": "Last 3 days",
			"1week": "Last week",
			"2weeks": "Last 2 weeks",
			"3weeks": "Last 3 weeks",
			"1month": "Last month",
			"2months": "Last 2 months",
			"3months": "Last 3 months",
			"6months": "Last 6 months",
			"1year": "Past year",
		},
		timeAgo: {
			seconds: "{0}s ago",
			minutes: "{0}m ago",
			hours: "{0}h ago",
			days: "{0}d ago",
		},
		apply: "Apply",
		ask: {
			removePointFromEntity: "Remove {2} {0} from {1}?",
			removeIdentitiesFromCustomer: "Remove {0} identities from customer?",
			removeIdentityFromCustomer: "Remove identity from customer?",
			confirm: "Confirm",
			save: "Save",
			saveChanges: "Save changes",
			cancel: "Cancel",
			close: "Close",
			note: "Note",
			identityRemovalsAreFinal: "Removals are final and cannot be undone or readded.",
			ok: "Ok",
			accept: "Accept",
			continue: "Continue",
		},
		noImage: "No image",
		allCustomersAtPoint: "Show all customers at station",
		eventsAtPoint: "Show latest events at station",
		allCustomersAtContainer: "Show all customers on container",
		eventsAtContainer: "Show latest events on container",
		eventsUnfilteredDays:
			"The table displays a maximum of {0} days of events by default. If you wish to display a longer period - specify the time interval, and add one additional filter of your own choosing.",
		eventsUnfilteredHours:
			"The table displays a maximum of {0} hours of events by default. If you wish to display a longer period - specify the time interval, and add one additional filter of your own choosing.",
		eventsFilteredDays: "The table displays a maximum of {0} days of events",
		latestCustomerEvents: "Show latest customer events",
		customersAllocated: "{0} customer(s) allocated",
		status: {
			null: "---",
			Active: "Active",
			ACTIVE: "Active",
			INACTIVE: "Inactive",
			DISABLED: "Disabled",
			ENABLED: "Enabled",
			PLANNED: "Planned",
			DEPRECATED: "Deprecated",
			WARNING: "Warning",
			undefined: "Unknown",
		},
		primaryPoint: "Primary point",
		fractions: "Fractions",
		fraction: EN.fractions,
		resources: "Admin panel",
		terminal: "Terminal",
		point: "Station",
		points: "Stations",
		station: "Station",
		stations: "Stations",
		container: "Container",
		containers: "Containers",
		movableContainers: "Containers",
		accessPoint: "Container",
		accessPoints: "Containers",
		inlet: "Inlet",
		inlets: "Inlets",
		customer: "Customer",
		customers: "Customers",
		operators: "Operators",
		operator: "Operator",
		users: "Users",
		activeUsers: "Active users",
		tenant: "Tenant",
		tenants: "Tenants",
		activeTenants: "Active Tenants",
		identities: "Identities",
		identity: "Identity",
		invoices: "Payment",
		backupPoint: "Backup station",
		backupPoints: "Backup stations",
		dashboard: "Dashboard",
		noResults: "No results",
		filterResults: "Filter results",
		rowSelected: "row selected",
		rowsSelected: "rows selected",
		starting: "waiting for login server",
		pagination: "Showing {0} - {1} of {2}",
		rowCount: "{0} rows in total",
		parent: "Parent",
		parentCompany: "Parent company",
		chain: "Chain",
		child: "Child",
		children: "Child",
		headers: {
			checkbox: " ",
			actions: "Actions",
			type: "Type",
			key: "Key",
			value: "Value",
			weight: "Weight",
			weightUnit: "Unit",
			ISO: "ISO",
			EM: "EM",
			identity: {
				ISO: "ISO",
				EM: "EM",
			},
			properties: {
				geoLocationName: "Address",
				geoLocationCode: "Code",
				municipality: "Municipality",
				streetCode: "Street code",
				houseNumber: "House number",
				address: "Address",
				crmId: "PAContract",
				fraction: "Fraction",
				productName: "Product name",
				productBrand: "Brand",
				filledVolume: "Volume",
				productionBatch: "Version",
			},
			externalKeys: {
				serviceGuid: "Service guid",
			},
			allocation: {
				name: "Name",
				code: "Code",
			},
			id: "ID",
			totalContainers: "Total containers",
			totalCustomers: "Total customers",
			operatorId: "OperatorID",
			name: "Name",
			unitCode: "Unit code",
			installation: {
				name: "Installation",
			},
			description: "Description",
			printedTag: "Tag",
			printed: "Tag",
			externalTag: "Tag",
			fillLevel: "Fill level",
			fillLevelDesc: "Fill level (percentage)",
			status: "Status",
			operatorName: "Operator name",
			customerName: "Customer name",
			customer: {
				name: "Customer",
			},
			containerName: "Container name",
			point: {
				id: "Point",
				name: "Container",
				fraction: "Fraksjon",
				productName: "Product Name",
				volume: "Volum",
				tag: "Tag",
				parent: {
					name: "Station",
				},
				terminal: {
					name: "Installation",
				},
				binCount: "Number of containers",
			},
			parent: {
				name: "Station",
			},
			terminal: {
				name: "Installation",
			},
			event: {
				type: "Event type",
			},
			result: "Result",
			reason: "Reason",
			timestamp: "Time",
			identityHolder: "Identity holder",
			station: "Station",
			place: "Place",
			category: "Category",
			stationType: "Station type",
			orgNr: "Organisation number",
			customerRepresentativeName: "Customer ref. name",
			customerClass: "Customer Class",
			postalCode: "Zip Code",
			visitId: "Visit ID",
			paymentAmountIncludingVAT: "Payment inc. VAT",
			paymentAmountExcludingVAT: "Payment ex. VAT",
			referenceText: "Reference",
			paymentType: "Payment type",
			totalBillableWeight: "Total billable weight",
			orderId: "Order ID",
			hatchSize: "Hatch size",
		},
		rejectReason: {
			identityUnknown: "The user does not have access to this container",
			identityInactive: "The user's access device is blocked or deactivated",
			identityDeleted: "The user's access device is deleted",
			containerFull: "The container was full and could not accept waste",
			containerClosed: "Disposal was attempted outside of the container's opening hours",
			notOpened: "The container's hatch was not opened",
			timeout: "The user took too long to open the hatch",
			unknown: "Unknown error",
		},
		invoice: "Payment",
		recyclingStationVisits: "Recycling station visits",
		list: "List",
		events: "Events",
		map: "Map",
		logout: "Log out",
		search: {
			placeholder: "Search for customers, infrastructure, identities...",
			unusedIdentity: "Unused identity",
		},
		actions: {
			edit: "Edit",
			exportCsv: "Export CSV",
			addPoint: "Add new point",
			addPoints: "Add new points",
			addInlet: "Add inlet",
			addContainer: "Add container",
			addExistingPoint: "Add station",
			addExistingPoints: "Add stations",
			removePoints: "Remove points",
			identitiesOverview: "Identities overview",
			addIdentity: "Add new identity",
			addIdentities: "Add identities",
			createAllocation: "New allocation",
			createPoint: "Create new point",
			editCustomerPoints: "Edit points",
			addToList: "Add to list",
			addNew: "Add new",
		},
		identityId: "Identity ID",
		identityISO: "Identity ISO",
		identityEM: "Identity EM",
		history: {
			title: "History",
			location: "Location",
			noHistory: "No history",
			noLocationHistory: "No location history",
			updated: "Updated",
			deleted: "Deleted",
			deletedFrom: "Deleted from",
			added: "Added",
			addedTo: "Added to",
			change: "Change",
			changes: "Changes",
			locations: "Locations",
			from: "From",
			to: "To",
			created: "Created",
			createdWithUser: "Created by {0}",
			createdWithoutUser: "Created",
			changedWithUser: "Changed by {0}",
			changedWithoutUser: "Changed",
			placedWithUser: "Placed by {0}",
			placedWithoutUser: "Placed",
			showHistory: "Show history",
			previouslyPlaced: "Previously placed",
			lastLocation: "Last location",
		},
		deleteCustomer: {
			title: "Delete customer",
			openModalText: "Delete customer",
			question: 'Whould you like to delete the customer "{0}"?',
			deleteButtonText: "Delete",
			confirmed: 'Customer "{0}" deleted',
			error: "There was a problem deleting the customer. Please reload the page and try again.",
		},
		deletionModal: {
			title: "Delete {0}",
			deleteCustomer: "Delete customer",
			deleteStation: "Delete station",
			deleteContainer: "Delete container",
			deleteOperator: "Delete operator",
			customers: "customer",
			containers: "container",
			points: "station",
			operators: "operator",
			question: 'Whould you like to delete the {0} "{1}"?',
			deleteButtonText: "Delete",
			confirmed: '{0} "{1}" deleted',
			error: "There was a problem deleting the container. Please reload the page and try again.",
		},
		extKeysAndProps: {
			title: "External keys and properties",
			openModalText: "Edit external keys and properties (Admin)",
			openTerminalModalText: "Edit external keys and properties for terminal (Admin)",
			externalKeys: "External keys",
			propterties: "Properties",
		},
		identitiesOverview: {
			title: "Manage keys for",
			addIdentities: "Add identities",
		},
		addIdentities: {
			title: "Add identities",
			description: "Add and edit identities in a list before adding them to the customer.",
			searchIdentities: "Search identities (min 4 characters)",
			alreadyAdded: "Identitiy is already added to the customer",
			couldNotAdd: "Could not add the identitiy specified, because it doesn't exist.",
			occupiedIdent: "Identity {0} is already added to customer {1}",
			addedSuccess: "Successfully added identity to customer",
			removedSuccess: "Successfully removed identity from customer",
			identsSaved: "All changes saved",
			filter: "Search in list",
		},
		multiKeyValue: {
			addNewLine: "Add new row",
		},
		monthsFull: {
			"0": "January",
			"1": "February",
			"2": "March",
			"3": "April",
			"4": "May",
			"5": "June",
			"6": "July",
			"7": "August",
			"8": "September",
			"9": "October",
			"10": "November",
			"11": "December",
		},
		"geoLocation.latitude": "locations latitude",
		"geoLocation.longitude": "locations longitude",
		geoLocation: {
			latitude: "location latitude",
			longitude: "location longitude",
		},
		properties: EN.properties,
		key: "Key",
		value: "Value",
		pointGeneric: "Point",
		accessParent: "Station",
		stationModal: {
			title: "Create a new station",
			titleAccessPoint: "Create new container",
			terminal: "Installation",
			station: "Station",
			newTerminal: "New installation",
			terminalName: "Installation name",
			stationName: "Station name",
			selectType: "Select type",
			selectStation: "Select station",
			selectFraction: "Select fraction",
			selectTerminal: "Select installation",
			selectStatus: "Select status",
			selectHatchSize: "Select hatch size",
			createTerminal: "Create installation",
			location: "GPS coordinates | address, city",
			errorLocation: "Invalid location!\n",
			locationExamples: "Examples\nGPS: 60.397076,5.324383\nAddress: Torgallmenningen 10, bergen",
			vendor: "Vendor",
			vendorContact: "Vendor contact (Name, phone, or email)",
			contact: "Contact information",
			CustomerID: "CustomerID",
			owner: "Station owner",
			municipality: "Municipality number",
			inlets: "Containers",
			newInlet: "Create a new container",
			noInlets: "No containers added",
			inletName: "Container name",
			containerInletName: "Inlet name",
			inletVendor: "Inlet vendor",
			hatches: {
				L: "Small",
				S: "Large",
				LS: "Small and Large",
			},
			errors: {
				fields: "Please fill highlighted fields",
				noTerminal: "Please select or create an installation",
				noStName: "Please enter station name",
				noStatus: "Please select status",
				noLocation: "Location is required when station is 'Active'",
				invalidLocation:
					"Location must be in GPS coordinates (60.397076,5.324383) or address, city (Torgallmenningen 10, bergen)",
				noInlets: "Atleast 1 container is required when station is 'Active'",
				noIName: "Please set name for inlet",
				noIFraction: "Please select fraction for inlet",
				noIStatus: "Please select status for inlet",
			},
		},
		customerModal: {
			title: "Create a new customer",
			basicInfo: "Customer information",
			points: "Access to station(s)",
			selectType: "Select customer type",
			selectOperator: "Select operator",
			operator: "Operator",
			allocationScheme: "Allocation scheme",
			customerName: "Customer name",
			description: "Description",
			error: "Please fill required customer information fields",
			errorMissing: "required fields missing",
			errorDuplicate: "customers will be overwritten",
			errorOperator: "Please select operator",
			errorStation: "Atleast 1 station required",
			properties: {
				taxNumber: "Organisation number",
				orgNumber: "Organisation number",
				geoLocationName: "Customer address",
				contact: "Contact info",
				CRMID: "CRMID",
			},
			externalKeys: {
				CRMID: "CRMID",
				CustomerID: "CustomerID",
			},
			customerTypes: {
				HB: "Household (HB)",
				HA: "Household (HA)",
				BA: "Business (BA)",
				BAVD: "Business (BAVD)",
				BUSINESS: "Business",
				BUSINESS_PARENT: "Business parent",
				HOUSEHOLD: "Household",
				HOUSEHOLD_PARENT: "Household parent",
			},
			allocationSchemes: {
				NONE: "None",
			},
			addStation: "Add station",
			add: "Add",
			downloadTemplate: "Download template",
			importTable: "Import from table",
			pasteHere: "Paste table here...",
		},
		containerType: {
			bin: "Bin",
			ball: "Ball",
			bag: "Bag",
			liftcontainer: "Lift container",
			container: "Container",
			undergroundContainer: "Underground container",
			comprimator: "Comprimator",
		},
		sorted: "Sorted waste",
		unsorted: "Unsorted waste",
		sortingDegreeProgress: "Sorting degree progression",
		sortingDegree: "Sorting degree",
		fractionDistribution: "Fraction distribution",
		fractionWasteProgress: "Waste amount progress",
		period: "Period",
		periods: {
			"30days": "Last 30 days",
			"6months": "Last 6 months",
			"7days": "Last week",
		},
		basicStatsLabels: {
			fractions: "That are sorted out",
			containers: "In waste room",
			customers: "That are registered",
			users: "Last 30 days",
			totalSorted: "Amount sorted",
			totalRest: "Amount waste",
			totalLastWeek: "Amount last week",
			inSystem: "In system",
		},
		centerOverview: "Center overview",
		tenantOverview: "Tenant overview",
		searchTenants: "Search tenant",
		searchCenters: "Search centers",
		registeredDisposals: "Registered disposals",
		totalThrows: "Disposals",
		totalWeight: "Total weight",
		fewRegisteredFractions: "Few registered fractions",
		fewRegisteredDisposals: "Few registered disposals",
		overviewOverTenants: "Overview over tenants",
		tenantDetails: "Tenant details",
		totalPoints: "Access points",
		totalCustomers: "Unique customers",
		totalIdentities: "Unique identities",
		totalFractions: "Unique fractions",
		inactivePoints: "Inactive points",
		rejectedDisposals: "Rejected disposals",
		successfulDisposals: "Successful disposals",
		mostActiveStations: "Most active stations",
		category: "Category",
		hints: {
			ifNoStation:
				'If the correct station does not appear in drop-down menu, you must first create a new station in the "Stations" view',
			identityManaging1: "Add and remove access devices for this customer.",
			identityManaging2:
				" • Devices which are already imported into the system will appear as suggestions when you enter text into one of the fields.",
			identityManaging3:
				" • Devices which are not previously imported into the system can be added by filling out all required fields manually.",
			identityManaging4: " • Only devices not already tied to another customer can be added.",
			identityManaging5: " • Devices can not be used until Status is set to Active.",
			unauthorized: `Your user currently does not have access to the content on this page.\nPlease contact <a href="mailto:support@carrot.tech">support@carrot.tech</a> to request access.`,
		},
		stationType: {
			cabinLocation: "Cabin",
			pickupLocation: "Organisation",
		},
		fillLevelTs: "Fill level updated",
		placeholders: {
			identityHolder: "Identity holder name",
			ISO: "ISO number",
			EM: "EM number",
			BossID: "BossID",
			printed: "Printed tag",
			printedTag: "Printed tag",
		},
		customerClass: {
			1: "Private",
			2: "Private",
			3: "Business",
			4: "Business",
			13: "Business",
			14: "Business",
			unknown: "Unknown",
		},
	},
	nb: {
		containerOptionID: containerOptionID_NO,
		unknown: "Ukjent",
		undefined: "Ikke definert",
		disabled: "Deaktivert",
		disable: "Deaktiver",
		enabled: "Aktivert",
		enable: "Aktiver",
		delete: "Slett",
		remove: "Fjern",
		accepted: "Akseptert",
		more: "Flere",
		type: {
			USE: "Kast",
			EMPTY: "Tømt",
		},
		isStagingMessage: "Du opererer nå i testmiljøet",
		selectDateTime: "Tidsinterval",
		from: "Fra",
		to: "Til",
		property: "Parameter",
		externalKey: "Ekstern nøkkel",
		priority: "Prioritet",
		allocation: "Tilordning",
		allocations: "Tilordninger",
		customerName: "Kundenavn",
		customerGeoLocationName: "Kundeadresse",
		loading: "Henter",
		error: "Feil",
		errors: {
			failedFetch: "Henting av data mislykkes.",
			failedSave: "Lagring mislyktes",
			failed: "Mislyktes",
		},
		success: {
			saved: "Lagret",
		},
		selectPoint: "Velg punkt",
		selectStation: "Velg stasjon",
		time: "Tidspunkt",
		interval: {
			custom: "Egendefinert",
			"1day": "I dag",
			"2days": "Siste 2 dager",
			"3days": "Siste 3 dager",
			"1week": "Siste uke",
			"2weeks": "Siste 2 uker",
			"3weeks": "Siste 3 uker",
			"1month": "Siste måned",
			"2months": "Siste 2 måneder",
			"3months": "Siste 3 måneder",
			"6months": "Siste 6 måneder",
			"1year": "Siste året",
		},
		timeAgo: {
			seconds: "{0}s siden",
			minutes: "{0}m siden",
			hours: "{0}t siden",
			days: "{0}d siden",
		},
		apply: "Velg",
		ask: {
			removePointFromEntity: "Fjern {2} {0} fra {1}?",
			removeIdentitiesFromCustomer: "Fjern {0} brikker fra kunde?",
			removeIdentityFromCustomer: "Fjern brikke fra kunde?",
			confirm: "Bekreft",
			save: "Lagre",
			saveChanges: "Lagre endringer",
			cancel: "Avbryt",
			close: "Lukk",
			note: "Merk",
			identityRemovalsAreFinal: "Sletting er endelig. Slettede brikker kan ikke legges til igjen.",
			ok: "Ok",
			accept: "Aksepterer",
			continue: "Forsett",
		},
		noImage: "Ingen bilde",
		customersAllocated: "{0} kunde(r) tilknyttet",
		allCustomersAtPoint: "Vis kunder tilknyttet stasjon",
		eventsAtPoint: "Vis siste hendelser på stasjon",
		allCustomersAtContainer: "Vis kunder tilknyttet beholder",
		eventsAtContainer: "Vis siste hendelser til beholder",
		eventsUnfilteredDays:
			"Tabellen er i utgangspunktet begrenset til å vise maks {0} dager med hendelser. Hvis du ønsker å se en lengre periode - spesifiser hvilket tidsintervall du ønsker å se, og legg til et valgfritt annet filter i tillegg.",
		eventsUnfilteredHours:
			"Tabellen er i utgangspunktet begrenset til å vise maks {0} timer med hendelser. Hvis du ønsker å se en lengre periode - spesifiser hvilket tidsintervall du ønsker å se, og legg til et valgfritt annet filter i tillegg.",
		eventsFilteredDays: "Tabellen er begrenset til å vise maks {0} dager med hendelser.",
		latestCustomerEvents: "Vis siste hendelser på kunde",
		status: {
			null: "---",
			Active: "Aktiv",
			ACTIVE: "Aktiv",
			INACTIVE: "Ikke aktiv",
			DISABLED: "Deaktivert",
			ENABLED: "Aktivert",
			PLANNED: "Planlagt",
			DEPRECATED: "Utfaset",
			WARNING: "Advarsel",
			undefined: "Unknown",
		},
		primaryPoint: "Primærpunkt",
		fractions: "Fraksjoner",
		fraction: NO.fractions,
		resources: "Admin panel",
		terminal: "Anlegg",
		point: "Stasjon",
		points: "Stasjoner",
		station: "Stasjon",
		stations: "Stasjoner",
		container: "Beholder",
		containers: "Beholdere",
		movableContainers: "Kontainere",
		accessPoint: "Beholder",
		accessPoints: "Beholdere",
		inlet: "Luke",
		inlets: "Luker",
		customer: "Kunde",
		customers: "Kunder",
		operators: "Operatører",
		operator: "Operatør",
		users: "Brukere",
		activeUsers: "Aktive brukere",
		tenant: "Leietaker",
		tenants: "Leietakere",
		activeTenants: "Aktive leietakere",
		identities: "Brikker",
		identity: "Brikke",
		invoices: "Betaling",
		backupPoint: "Reservestasjon",
		backupPoints: "Reservestasjoner",
		dashboard: "Dashbord",
		noResults: "Ingen treff",
		filterResults: "Filtrer visning",
		rowSelected: "rad valgt",
		rowsSelected: "rader valgt",
		starting: "venter på svar fra loginserver",
		pagination: "Viser {0} - {1} av {2}",
		rowCount: "Totalt {0} rader",
		parent: "Mor",
		parentCompany: "Morselskap",
		chain: "Kjede",
		child: "Datter",
		children: "Døtre",
		headers: {
			checkbox: " ",
			actions: "Handlinger",
			type: "Type",
			key: "Nøkkel",
			value: "Verdi",
			weight: "Vekt",
			weightUnit: "Enhet",
			ISO: "ISO-nummer",
			EM: "EM-nummer",
			identity: {
				ISO: "ISO",
				EM: "EM",
			},
			allocation: {
				name: "Navn",
				code: "Kode",
			},
			properties: {
				geoLocationName: "Adresse",
				geoLocationCode: "Kode",
				municipality: "Kommune",
				streetCode: "Gatenavnkode",
				houseNumber: "Husnummer",
				address: "Adresse",
				crmId: "PAAvtale",
				fraction: "Fraksjon",
				productName: "Produktnavn",
				productBrand: "Merke",
				filledVolume: "Volum",
				productionBatch: "Versjon",
			},
			externalKeys: {
				serviceGuid: "Service guid",
			},
			id: "ID",
			totalContainers: "Antall beholdere",
			totalCustomers: "Antall kunder",
			operatorId: "OperatørID",
			name: "Navn",
			unitCode: "Enhetskode",
			installation: {
				name: "Anlegg",
			},
			description: "Kommentar",
			printedTag: "Merkelapp",
			printed: "Merkelapp",
			externalTag: "Tagg",
			fillLevel: "Fyllingsgrad",
			fillLevelDesc: "Fyllingsgrad (prosent)",
			status: "Status",
			operatorName: "Operatørnavn",
			customerName: "Kundenavn",
			customer: {
				name: "Kunde",
			},
			containerName: "Beholdernavn",
			point: {
				id: "Nedkast",
				name: "Beholder",
				fraction: "Fraksjon",
				productName: "Produktnavn",
				volume: "Volum",
				tag: "Tag",
				parent: {
					name: "Stasjon",
				},
				terminal: {
					name: "Anlegg",
				},
				binCount: "Antall beholdere",
			},
			parent: {
				name: "Stasjon",
			},
			terminal: {
				name: "Anlegg",
			},
			event: {
				type: "Hendelsestype",
			},
			result: "Resultat",
			reason: "Årsak",
			timestamp: "Tidspunkt",
			identityHolder: "Brikkeholders navn",
			station: "Stasjon",
			place: "Sted",
			category: "Kategori",
			stationType: "Stasjonstype",
			orgNr: "Org nr",
			customerClass: "Kundeklasse",
			postalCode: "Post nr.",
			visitId: "Besøks ID",
			paymentAmountIncludingVAT: "Beløp ink. MVA",
			paymentAmountExcludingVAT: "Beløp ex. MVA",
			referenceText: "Referanse",
			paymentType: "Betalingstype",
			totalBillableWeight: "Total betalbar vekt",
			customerRepresentativeName: "Kunde ref. navn",
			orderId: "Ordre ID",
			hatchSize: "Lukestørrelse",
		},
		rejectReason: {
			identityUnknown: "Brukeren har ikke tilgang til denne beholderen",
			identityInactive: "Brukerens adgangsenhet er blokkert eller deaktivert",
			identityDeleted: "Brukerens adgangsenhet er slettet",
			containerFull: "Beholderen var full og kunne ikke ta imot avfall",
			containerClosed: "Kast ble forsøkt utenfor åpningstider",
			notOpened: "Beholderens luke ble ikke åpnet",
			timeout: "Brukeren brukte for lang tid uten å åpne luken",
			unknown: "Ukjent feil",
		},
		invoice: "Betaling",
		recyclingStationVisits: "Besøk på gjenvinningsstasjon",
		list: "Liste",
		events: "Hendelser",
		map: "Kart",
		logout: "Logg ut",
		search: {
			placeholder: "Søk etter kunder, infrastruktur, brikker...",
			unusedIdentity: "Ubrukt ID",
		},
		actions: {
			edit: "Rediger",
			exportCsv: "Eksporter CSV",
			addPoint: "Legg til nytt punkt",
			addPoints: "Legg til nye punkter",
			addInlet: "Legg til beholder",
			addContainer: "Legg til kontainer",
			addExistingPoint: "Legg til stasjon",
			addExistingPoints: "Legg til stasjoner",
			removePoints: "Fjern punkter",
			identitiesOverview: "Brikkeoversikt",
			addIdentity: "Legg til ny brikke",
			addIdentities: "Legg til brikker",
			createAllocation: "Ny tilordning",
			createPoint: "Ny punkt",
			editCustomerPoints: "Endre punkter",
			addToList: "Legg til liste",
			addNew: "Legg til ny",
		},
		identityId: "Brikkeid",
		identityISO: "Brikke ISO",
		identityEM: "Brikke EM",
		history: {
			title: "Historikk",
			location: "Stedets",
			noHistory: "Ingen historikk",
			noLocationHistory: "Ingen lokasjons historikk",
			updated: "Oppdatering av",
			deleted: "Slettet",
			deletedFrom: "Slettet fra",
			added: "Tilleggelse av",
			addedTo: "Tilleggelse av",
			change: "Endring",
			changes: "Endringer",
			locations: "Lokasjoner",
			from: "Fra",
			to: "Til",
			created: "Opprettet",
			createdWithUser: "Opprettet av {0}",
			createdWithoutUser: "Opprettet",
			changedWithUser: "Endret av {0}",
			changedWithoutUser: "Endret",
			placedWithUser: "Plassert av {0}",
			placedWithoutUser: "Plassert",
			showHistory: "Vis historikk",
			previouslyPlaced: "Sist plassert",
			lastLocation: "Siste stedet",
		},
		deleteCustomer: {
			title: "Slett kunde",
			openModalText: "Slett kunde",
			question: 'Ønsker du å slette kunden "{0}"?',
			deleteButtonText: "Slett",
			confirmed: 'Kunde "{0}" slettet',
			error:
				"Det oppstod et problem ved sletting av kunden. Vennligst oppdater siden og forsøk igjen.",
		},
		deletionModal: {
			title: "Slett {0}",
			deleteCustomer: "Slett kunde",
			deleteStation: "Slett stasjon",
			deleteContainer: "Slett beholder",
			deleteOperator: "Slett operatør",
			customers: "kunde",
			containers: "beholder",
			points: "stasjon",
			operators: "operatør",
			question: 'Ønsker du å slette {0} "{1}"?',
			deleteButtonText: "Slett",
			confirmed: '{0} "{1}" slettet',
			error:
				"Det oppstod et problem ved sletting av beholder. Vennligst oppdater siden og forsøk igjen.",
		},
		extKeysAndProps: {
			title: "Eksterne nøkler og egenskaper",
			openModalText: "Rediger eksterne nøkler og egenskaper (Admin)",
			openTerminalModalText: "Rediger eksterne nøkler og egenskaper til anlegg (Admin)",
			externalKeys: "Eksterne nøkler",
			propterties: "Egenskaper",
		},
		identitiesOverview: {
			title: "Administrer brikker for",
			addIdentities: "Legg til brikker",
		},
		addIdentities: {
			title: "Legg til brikker",
			description: "Legg til og rediger brikker i en egen liste før de legges til kunden.",
			searchIdentities: "Søk brikker (minimum 4 tegn)",
			alreadyAdded: "Brikken er allerede lagt til kunden",
			couldNotAdd: "Kunne ikke legge til den spesifikke brikken siden den ikke var å finne.",
			occupiedIdent: 'Brikke "{0}" er allerede lagt til kunde "{1}"',
			addedSuccess: "Brikke lagt til kunden",
			removedSuccess: "Brikke fjernet fra kunden",
			identsSaved: "Alle endringer lagret",
			filter: "Søk i brikkene",
		},
		multiKeyValue: {
			addNewLine: "Legg til rad",
		},
		monthsFull: {
			"0": "januar",
			"1": "februar",
			"2": "mars",
			"3": "april",
			"4": "mai",
			"5": "juni",
			"6": "juli",
			"7": "august",
			"8": "september",
			"9": "oktober",
			"10": "november",
			"11": "desember",
		},
		"geoLocation.latitude": "lokasjons breddegrad",
		"geoLocation.longitude": "lokasjons lengdegrad",
		geoLocation: {
			latitude: "lokasjons breddegrad",
			longitude: "lokasjons lengdegrad",
		},
		properties: NO.properties,
		key: "Nøkkel",
		value: "Verdi",
		pointGeneric: "Punkt",
		accessParent: "Stasjon",
		stationModal: {
			title: "Opprett ny stasjon",
			titleAccessPoint: "Opprett ny beholder",
			terminal: "Anlegg",
			station: "Stasjon",
			newTerminal: "Nytt anlegg",
			terminalName: "Anleggsnavn",
			stationName: "Stasjonsnavn",
			selectType: "Velg type",
			selectStation: "Velg stasjon",
			selectFraction: "Velg fraksjon",
			selectTerminal: "Velg anlegg",
			selectStatus: "Velg status",
			selectHatchSize: "Velg lukestørrelse",
			createTerminal: "Opprett nytt anlegg",
			location: "GPS koordinater | adresse, by",
			errorLocation: "Ugyldig lokasjon!\n",
			locationExamples: "Eksempler\nGPS: 60.397076,5.324383\nAdresse: Torgallmenningen 10, bergen",
			vendor: "Leverandør",
			vendorContact: "Leverandør kontakt (Navn, mobil, epost)",
			contact: "Kontaktinformasjon",
			CustomerID: "KundeID",
			owner: "Stasjonseier",
			municipality: "Kommunenummer",
			inlets: "Beholdere",
			newInlet: "Opprett ny beholder",
			noInlets: "Ingen beholdere lagt til",
			inletName: "Beholdernavn",
			containerInletName: "Lukenavn",
			inletVendor: "Leverandør av nedkast",
			hatches: {
				L: "Liten",
				S: "Stor",
				LS: "Liten og Stor",
			},
			errors: {
				fields: "Vennligst fyll ut merkede felter",
				noTerminal: "Velg eller opprett et anlegg",
				noStName: "Vennligst oppgi et stasjonsnavn",
				noStatus: "Velg status",
				noLocation: "Lokasjon er påkrevet ved en aktiv stasjon",
				invalidLocation:
					"Lokasjon må være enten GPS koordinater (60.397076,5.324383) eller adresse, by (Torgallmenningen 10, bergen)",
				noInlets: "Minst ett beholder er påkrevet ved en aktiv stasjon",
				noIName: "Vennligst oppgi et navn på nedkastet",
				noIFraction: "Vennligst oppgi nedkastets fraksjonstype",
				noIStatus: "Vennligst oppgi status på nedkastet",
			},
		},
		customerModal: {
			title: "Opprett ny kunde",
			basicInfo: "Kundeinformasjon",
			points: "Tilgang til stasjon(er)",
			selectType: "Velg kunde type",
			selectOperator: "Velg operatør",
			operator: "Operatør",
			allocationScheme: "Tilordningsregel",
			customerName: "Kundenavn",
			description: "Beskrivelse",
			error: "Vennligst fyll ut nødvendig kundeinformasjon",
			errorMissing: "påkrevde feltene mangler",
			errorDuplicate: "kunder blir overskrevet",
			errorOperator: "Vennligst velg operatør",
			errorStation: "Vennligst legg til stasjon",
			properties: {
				taxNumber: "Organisasjonsnummer",
				orgNumber: "Organisasjonsnummer",
				geoLocationName: "Kundeadresse",
				contact: "Kontaktinformasjon",
				CRMID: "CRMID",
			},
			externalKeys: {
				CRMID: "CRMID",
				CustomerID: "KundeID",
			},
			customerTypes: {
				HB: "Hushold (HB)",
				HA: "Hushold (HA)",
				BA: "Næring (BA)",
				BAVD: "Næring (BAVD)",
				BUSINESS: "Næring",
				BUSINESS_PARENT: "Morselskap",
				HOUSEHOLD: "Husholdning",
				HOUSEHOLD_PARENT: "Husholdning mor",
			},
			allocationSchemes: {
				NONE: "Ingen",
			},
			addStation: "Knytt til stasjon",
			add: "Legg til",
			downloadTemplate: "Last ned mal",
			importTable: "Importer fra tabell",
			pasteHere: "Lim inn tabellen her...",
		},
		containerType: {
			bin: "Spann",
			ball: "Ball",
			bag: "Sekk",
			liftcontainer: "Liftcontainer",
			container: "Kontainer",
			undergroundContainer: "Nedgravd kontainer",
			comprimator: "Komprimator",
		},
		sorted: "Utsortert avfall",
		unsorted: "Restavfall",
		sortingDegreeProgress: "Utvikling i Sorteringsgrad",
		sortingDegree: "Sorteringsgrad",
		fractionDistribution: "Fordeling per fraksjon",
		fractionWasteProgress: "Utvikling i avfallsmengder",
		period: "Periode",
		periods: {
			"30days": "Siste 30 dager",
			"6months": "Siste 6 måneder",
			"7days": "Siste uke",
		},
		basicStatsLabels: {
			fractions: "Som utsorteres",
			containers: "I avfallsrom",
			customers: "Som er registrert",
			users: "Siste 30 dager",
			totalSorted: "Mengde utsortert",
			totalRest: "Mengde restavfall",
			totalLastWeek: "Antall siste uke",
			inSystem: "I systemet",
		},
		centerOverview: "Kjøpesenteroversikt",
		tenantOverview: "Leietakeroppfølging",
		searchTenants: "Søk etter leietakere",
		searchCenters: "Søk etter sentere",
		registeredDisposals: "Registrerte kast",
		totalThrows: "Antall kast",
		totalWeight: "Total registrert vekt",
		fewRegisteredFractions: "Få registrerte fraksjoner",
		fewRegisteredDisposals: "Få selvregisteringer",
		overviewOverTenants: "Oversikt over leietakere",
		tenantDetails: "Detaljer per leietaker",
		totalPoints: "Antall nedkast",
		totalCustomers: "Antall kunder",
		totalIdentities: "Antall identiteter",
		totalFractions: "Antall avfallstyper",
		inactivePoints: "Inaktive nedkast",
		rejectedDisposals: "Avviste hendelser",
		successfulDisposals: "Aksepterte hendelser",
		mostActiveStations: "Mest aktive stasjoner",
		category: "Kategori",
		hints: {
			ifNoStation:
				'Dersom riktig stasjon ikke vises i valgmenyen må det først opprettes en ny under "Stasjoner"',
			identityManaging1: "Her kan du legge til adgangsbrikker på kunden.",
			identityManaging2:
				" • Brikker som allerede er importert vil dukke opp som forslag når du begynner å søke etter ett av feltene.",
			identityManaging3:
				" • Brikker som ikke er importert kan legges til ved å fylle ut alle påkrevde felt manuelt.",
			identityManaging4: " • Kun brikker som ikke allerede er tilknyttet en kunde kan legges til.",
			identityManaging5: " • Brikker kan ikke tas i bruk før Status er satt til Aktiv.",
			unauthorized: `Brukeren din har ikke tilgang til innholdet på denne siden.\nVennligst kontakt <a href="mailto:support@carrot.tech">support@carrot.tech</a> for å be om tilgang.`,
		},
		stationType: {
			cabinLocation: "Hytte",
			pickupLocation: "Næring",
		},
		fillLevelTs: "Fyllingsgrad oppdatert",
		placeholders: {
			identityHolder: "Skriv inn brikkeholders navn",
			ISO: "Skriv inn ISO-nummer",
			EM: "Skriv inn EM-nummer",
			BossID: "Skriv inn BossID",
			printed: "Skriv inn merkelapp",
			printedTag: "Skriv inn merkelapp",
		},
		customerClass: {
			1: "Privat",
			2: "Privat",
			3: "Næring",
			4: "Næring",
			13: "Næring",
			14: "Næring",
			unknown: "Ukjent",
		},
	},
}
