import { get } from "lodash"
import React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import exportCSV from "../exportCSV"
import { IListViewConfig } from "../types"
import DataGrid from "../_components/DataGrid/DataGrid"

function csvOptions(headers: IListViewConfig["headers"], trans: any) {
	const filename = `${trans("customers")}.csv`
	const fields = headers.map(h => ({
		label: trans(h.name ? h.name : `headers.${h.path}`),
		value: h.path,
	}))

	return { filename, fields }
}

const getFractionName = (trans: any) => (point: any) => {
	const fraction = get(point, "properties.fraction", "")
	if (!fraction) {
		return ""
	}
	const f = trans(`fraction.${fraction}`)
	return f ? f : fraction
}

const getTypeName = (trans: any) => (point: any) => {
	const type = get(point, "properties.containerType", "")
	if (!type) {
		return ""
	}
	const f = trans(`containerType.${type}`)
	return f ? f : type
}

const getStatusName = (trans: any) => (point: any) => {
	const status = get(point, "properties.status", "")
	if (!status) {
		return ""
	}
	const f = trans(`type.${status}`)
	return f ? f : status
}

const Grid = (props: any) => {
	const { accessPoints, headers, columnSizes } = props
	const history = useHistory()
	const { trans } = useTranslation()
	const ui = useSiteConfig(state => state.siteConfig.containers.drawer.ui)

	const typeMatch = useRouteMatch<{ type?: string }>(`/resources/:type`)

	const actions = [
		{
			label: "actions.exportCsv",
			func: exportCSV(csvOptions(headers, trans)),
		},
	]

	return (
		<DataGrid
			headers={headers}
			entityType={"containers"}
			columnSizes={columnSizes}
			rows={accessPoints}
			actions={actions}
			overrides={{
				"properties.fraction": getFractionName(trans),
				"properties.containerType": getTypeName(trans),
				"properties.status": getStatusName(trans),
			}}
			allowEmptyField
			onRowClick={
				ui === "drawer"
					? (container: any) =>
							history.replace({
								pathname: `/resources/${typeMatch?.params?.type || "containers"}/${container.id}`,
								search: history.location.search,
							})
					: undefined
			}
		/>
	)
}

export default Grid
