export const orkla_translations: any = {
	en: {
		containerOptionID: {
			KSG0: "Shower gel 0.9L",
			// KSG2100: "Shower gel 2.1L",
			KDW0: "Dish washing soap 0.9L",
			// KDW2100: "Dish washing soap 2.1L",
			KKS0: "Kitchen spray 0.9L",
			// KKS2100: "Kitchen spray 2.1L",
			KHS0: "Hand soap 0.9L",
			// KHS2100: "Hand soap 2.1L",
			// KLD900: "Laundry detergent 0.9L",
			KLD0: "Laundry detergent 2.1L",
			LHS0: "Hand soap 0.9L",
			// LHS2100: "Hand soap 2.1L",
			// OCL900: "Laundry detergent 0.9L",
			OCL0: "Laundry detergent 2.1L",
			ZUDW0: "Dish washing soap 0.9L",
			// ZUDW2100: "Dish washing soap 2.1L",
			GK0: "Kitchen spray 0.9L",
			GHT0: "Hand soap 0.9L",
			GDN0: "Dish washing soap 0.9L",
			GFKL0: "Laundry detergent Lindblomma 2.0L",
			GFK0: "Laundry detergent 2.0L",
			MLD0: "Laundry detergent 2.0L",
		},
		containerType: {
			KSG: "KLAR Shower gel",
			KDW: "KLAR Dish washing soap",
			KKS: "KLAR Kitchen spray",
			KHS: "KLAR Hand soap",
			KLD: "KLAR Laundry detergent",
			LHS: "LANO Hand soap",
			OCL: "OMO Laundry detergent",
			ZUDW: "ZALO Dish washing soap",
			GK: "Grumme Kitchen spray",
			GHT: "Grumme Hand soap",
			GDN: "Grumme Dish washing soap",
			GFKL: "Grumme Laundry detergent Lindblomma",
			GFK: "Grumme Laundry detergent",
			MLD: "MILO Original Laundry detergent",
		},
		type: {
			USE: null,
			EMPTY: null,
			EMPTYING_REQUEST: "Refill order",
			RETURN: "Recieved",
			IN: "Recieved",
			DISPATCH: "Delivery",
			OUT: "Delivery",
			FILL: "Filled",
			WASH: "Reconditioned",
			DAMAGE: "Deviation",
			ORDER: "Ordered",
			PRERECONDITION: "To reconditioning",
			emptying_request: "Refill order",
			return: "Recieved",
			in: "Recieved",
			dispatch: "Delivery",
			out: "Delivery",
			fill: "Filled",
			wash: "Reconditioned",
			damage: "Deviation",
			order: "Ordered",
			prerecondition: "To reconditioning",
		},
		damageType: {
			dirty: "Dirty",
			dent: "Dent",
			other: "Other",
		},
		selectPoint: "Select container",
		ask: {
			removePointFromCustomer: "Remove container {0} from customer?",
		},
		allCustomersAtPoint: "Show all customers tied to container",
		eventsAtPoint: "Show the lastest events of container",
		primaryPoint: "Containers",
		points: "Containers",
		stations: "Containers",
		headers: {
			installation: {
				name: "Container",
			},
			point: {
				parent: {
					name: "Container",
				},
				properties: {
					volume: "Volume",
					containerType: "Product",
				},
			},
			customer: {
				externalKeys: {
					helthjemCode: "Helthjem code",
				},
			},
			properties: {
				volume: "Volume",
				status: "Status",
				version: "Version",
				containerType: "Product",
				orderId: "OrderId",
				customerId: "CustomerId",
				shippingId: "DeliveryId",
				dispatchDate: "Dispatch date",
				products: "Products",
				damageType: "Damage type",
				damageDescription: "Damage desc",
				damageImages: "Damage images",
			},
		},
		backupPoints: "Backupcontainers",
		actions: {
			exportCsv: "Export CSV",
			addPoint: "Add new container",
			addPoints: "Add new containers",
			removePoints: "Remove containers",
			addIdentity: "Add new identity",
			addIdentities: "Add identities",
			editCustomerPoints: "Edit containers",
		},
		identityId: "Identity id",
		identityISO: "Identity ISO",
		identityEM: "Identity EM",
		stationModal: {
			selectProduct: "Select product",
		},
	},
	nb: {
		containerOptionID: {
			KSG0: "Dusjsåpe 0.9L",
			// KSG2100: "Dusjsåpe 2.1L",
			KDW0: "Oppvask 0.9L",
			// KDW2100: "Oppvask 2.1L",
			KKS0: "Kjøkkenspray 0.9L",
			// KKS2100: "Kjøkkenspray 2.1L",
			KHS0: "Håndsåpe 0.9L",
			// KHS2100: "Håndsåpe 2.1L",
			// KLD900: "Tøyvask 0.9L",
			KLD0: "Tøyvask 2.1L",
			LHS0: "Håndsåpe 0.9L",
			// LHS2100: "Håndsåpe 2.1L",
			// OCL900: "Tøyvask 0.9L",
			OCL0: "Tøyvask 2.1L",
			ZUDW0: "Oppvask 0.9L",
			// ZUDW2100: "Oppvask 2.1L",
			GK0: "Kjøkkenspray 0.9L",
			GHT0: "Håndsåpe 0.9L",
			GDN0: "Kjøkkenspray 0.9L",
			GFKL0: "Tøyvask Lindblomma 2.0L",
			GFK0: "Tøyvask 2.0L",
			MLD0: "Tøyvask 2.0L",
		},
		containerType: {
			KSG: "KLAR Dusjsåpe",
			KDW: "KLAR Oppvask",
			KKS: "KLAR Kjøkkenspray",
			KHS: "KLAR Håndsåpe",
			KLD: "KLAR Tøyvask",
			LHS: "LANO Håndsåpe",
			OCL: "OMO Tøyvask",
			ZUDW: "ZALO Oppvask",
			GK: "Grumme Kjøkkenspray",
			GHT: "Grumme Håndsåpe",
			GDN: "Grumme Oppvask",
			GFKL: "Grumme Tøyvask Lindblomma",
			GFK: "Grumme Tøyvask",
			MLD: "MILO Original Tøyvask",
		},
		type: {
			USE: null,
			EMPTY: null,
			EMPTYING_REQUEST: "Bestilt påfyll",
			RETURN: "Mottatt",
			IN: "Mottatt",
			DISPATCH: "Levering",
			OUT: "Levering",
			FILL: "Fylt",
			WASH: "Rekondisjonert",
			DAMAGE: "Avvik",
			ORDER: "Bestilling",
			PRERECONDITION: "Til rekondisjonering",
			emptying_request: "Bestilt påfyll",
			return: "Mottatt",
			in: "Mottatt",
			dispatch: "Levering",
			out: "Levering",
			fill: "Fylt",
			wash: "Rekondisjonert",
			damage: "Avvik",
			order: "Bestilling",
			prerecondition: "Til rekondisjonering",
		},
		damageType: {
			dirty: "Skitten",
			dent: "Bulket",
			other: "Annet",
		},
		selectPoint: "Velg beholder",
		ask: {
			removePointFromCustomer: "Fjern beholderen {0} fra kunde?",
		},
		allCustomersAtPoint: "Vis alle kunder knyttet til beholder",
		eventsAtPoint: "Vis beholderens siste hendelser",
		headers: {
			installation: {
				name: "Beholder",
			},
			point: {
				parent: {
					name: "Beholder",
				},
				properties: {
					volume: "Volum",
					containerType: "Produkt",
				},
			},
			customer: {
				externalKeys: {
					helthjemCode: "Helthjem kode",
				},
			},
			properties: {
				volume: "Volum",
				status: "Status",
				version: "Versjon",
				containerType: "Produkt",
				orderId: "Ordrenr",
				customerId: "Kundenr",
				shippingId: "Leveringsnr",
				dispatchDate: "Leveringsdato",
				products: "Ordre produkter",
				damageType: "Avvikstype",
				damageDescription: "Avviksbeskrivelse",
				damageImages: "Avviksbilder",
			},
		},
		container: "Beholder",
		containers: "Beholdere",
		primaryPoint: "Beholdere",
		points: "Beholdere",
		stations: "Beholdere",
		backupPoints: "Reservebeholdere",
		actions: {
			exportCsv: "Eksporter CSV",
			addPoint: "Legg til ny beholder",
			addPoints: "Legg til nye beholdere",
			removePoints: "Fjern beholdere",
			addIdentity: "Legg til identitet",
			addIdentities: "Legg til identiteter",
			editCustomerPoints: "Endre beholdere",
		},
		identityId: "Identitets id",
		identityISO: "Identitets ISO",
		identityEM: "Identitets EM",
		stationModal: {
			selectProduct: "Velg produkt",
		},
	},
}
